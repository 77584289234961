import { ReactNode, useState, useEffect, FunctionComponent, useRef } from 'react'
import { useRouter } from 'next/router'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  VStack,
  HStack,
  Container,
  Grid,
  GridItem,
  AspectRatio,
  ButtonProps,
  Link,
} from '@chakra-ui/react'
import { CartState } from '@Types/cart/Cart'
import OrderStatusModal from 'composable/components/account/pages/account-dashboard/components/order-status'
import { BrandLogo } from 'composable/components/brand-logo'
import { useComposable } from 'composable/components/composable-provider'
import { LOGO_ASPECT_RATIO } from 'composable/components/general/constants'
import { useUpdateCustomerGroup } from 'composable/components/hooks/useUpdateCustomerGroup'
import { useFormat } from 'helpers/hooks/useFormat'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import useCartCT from 'frontastic/hooks/useCart'
import { CartButton } from './cartButton'
import { GRID_TEMPLATES_COLUMNS } from './constants'
import { FindStoreButton } from './findStoreButton'
import { OrderStatusButton } from './orderStatusButton'
import { ProfileButton } from './profileButton'

interface LegacyHeaderProps {
  brandLogo: any
  homeUrl: string
  cartQuantity?: number
  cartState?: CartState
  searchComponent?: ReactNode
  verticalButtonProps?: ButtonProps
}

export const LegacyHeader: FunctionComponent<LegacyHeaderProps> = ({
  brandLogo,
  homeUrl,
  verticalButtonProps,
  searchComponent,
}) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { cartDrawer, megaDrawer } = useComposable()
  const { loggedIn } = useAccountCT()
  const { updateCustomerGroupIfNeeded } = useUpdateCustomerGroup()
  const router = useRouter()
  const { totalItems, recalculateCart } = useCartCT()
  const refOrderStatusButton = useRef(null)

  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)

  const isCartPage = router.asPath === '/cart'

  useEffect(() => {
    if (loggedIn) {
      updateCustomerGroupIfNeeded()
    }
  }, [loggedIn, updateCustomerGroupIfNeeded])

  useEffect(() => {
    window.addEventListener('userLoggedIn', () => recalculateCart())

    return () => {
      window.removeEventListener('userLoggedIn', () => recalculateCart())
    }
  }, [])

  const MobileHeaderMenu = (props) => {
    return (
      <VStack {...props}>
        <HStack id="container-ud" width="100%" justifyContent={'space-between'}>
          <HStack flex={1} gap={3}>
            <Button
              name="menu"
              aria-label="menu"
              width={'40px'}
              minWidth={'auto'}
              variant="unstyled"
              onClick={() => megaDrawer.onOpen()}
            >
              <HamburgerIcon width={'26px'} height={'26px'} />
            </Button>

            {brandLogo?.media && (
              <Box minW="42px" minH="24px">
                <Link href={homeUrl}>
                  <AspectRatio ratio={LOGO_ASPECT_RATIO} maxW="80px">
                    <BrandLogo
                      title={formatMessage({ id: 'header.logo.title' })}
                      src={brandLogo.media.file}
                      alt={brandLogo.title.en_US ?? 'Brand Logo'}
                      style={{ objectFit: 'contain' }}
                      width={80}
                      height={45}
                    />
                  </AspectRatio>
                </Link>
              </Box>
            )}
          </HStack>
          <HStack alignItems={'center'} gap={4}>
            <ProfileButton />

            <CartButton
              verticalButtonProps={{
                ...verticalButtonProps,
                width: '32px',
                _active: {
                  bgColor: 'brand.accent',
                  color: 'text.primary',
                },
              }}
              isCartPage={isCartPage}
              cartQuantity={totalItems}
              onOpenCartDrawer={() => {
                recalculateCart()
                cartDrawer.onOpen()
              }}
            />
          </HStack>
        </HStack>
        {searchComponent}
      </VStack>
    )
  }

  const DesktopHeaderMenu = (props) => {
    return (
      <Grid gridTemplateColumns={GRID_TEMPLATES_COLUMNS} gap={8} alignItems={'center'} {...props}>
        <GridItem display="flex" flexDirection="column" justifyContent="center">
          {brandLogo && (
            <Box as={Link} href={homeUrl} position="relative">
              <AspectRatio maxW="71px" ratio={LOGO_ASPECT_RATIO} cursor="pointer">
                <BrandLogo
                  title={formatMessage({ id: 'header.logo.title' })}
                  src={brandLogo.media.file}
                  alt={brandLogo.title?.en_US ?? ''}
                  style={{ objectFit: 'contain' }}
                  width={71}
                  height={40}
                />
              </AspectRatio>
            </Box>
          )}
        </GridItem>
        <GridItem role={'search'} title={formatMessage({ id: 'header.search.title' })}>
          {searchComponent}
        </GridItem>
        <GridItem>
          <HStack justifyContent="flex-end" gap={2}>
            <FindStoreButton />
            <OrderStatusButton
              ref={refOrderStatusButton}
              handleOpenOrderStatusModal={() => setIsOrderStatusModalOpen(true)}
            />
            <ProfileButton />
            <CartButton
              verticalButtonProps={{
                ...verticalButtonProps,
                width: '40px',
              }}
              isCartPage={isCartPage}
              cartQuantity={totalItems}
              onOpenCartDrawer={() => {
                recalculateCart()
                cartDrawer.onOpen()
              }}
            />
          </HStack>
        </GridItem>
      </Grid>
    )
  }

  return (
    <Box w="100%">
      <Container maxW="100rem" px={{ base: 4, lg: 10 }} py={3}>
        <MobileHeaderMenu display={{ base: 'flex', lg: 'none' }} />
        <DesktopHeaderMenu display={{ base: 'none', lg: 'grid' }} />

        <OrderStatusModal
          finalFocus={refOrderStatusButton}
          isOpen={isOrderStatusModalOpen}
          onClose={() => setIsOrderStatusModalOpen(false)}
        />
      </Container>
    </Box>
  )
}
