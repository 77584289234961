import { VStack } from '@chakra-ui/react'
import { LegacyHeader } from 'atg/components/legacy-header-r2'
import { GlobalSearch } from 'composable/components/global-search/global-search'
import {
  algoliaIndexNameResolver,
  algoliaQuerySuggestionsIndexNameResolver,
} from 'composable/components/global-search-shared/utils'
import useCartCT from 'frontastic/hooks/useCart'
import { FrontasticImage } from 'frontastic/lib/image/types'

type TasticProps = {
  data: {
    logo?: FrontasticImage & { title: { en_US: string } }
    homeUrl?: string
  }
}

const LegacyHeaderTasticR2: React.FC<TasticProps> = ({ data }) => {
  const { totalItems } = useCartCT()
  const { logo, homeUrl } = data

  return (
    <VStack>
      <LegacyHeader
        brandLogo={logo}
        homeUrl={homeUrl || '/'}
        searchComponent={
          <GlobalSearch
            algoliaIndexNameResolver={algoliaIndexNameResolver}
            algoliaQuerySuggestionsIndexNameResolver={algoliaQuerySuggestionsIndexNameResolver}
          />
        }
        verticalButtonProps={{
          textDecoration: 'none',
          size: 'md',
          minW: 'auto',
        }}
        cartQuantity={totalItems}
      />
    </VStack>
  )
}

export default LegacyHeaderTasticR2
